import React from 'react'
import { FaTrash } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'

const Modal = ({
	isOpen,
	onClose,
	onConfirm,
	title,
	children,
	showAction,
	firstText,
	secondText,
}) => {
	if (!isOpen) return null

	return (
		<div className="text-center fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 p-4">
			<div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg md:max-w-3xl relative">
				<button
					onClick={onClose}
					className="absolute top-2 right-2 text-red-500 hover:text-red-700"
				>
					<IoClose size={32} />
				</button>
				<h2 className="text-2xl font-bold mb-4">{title}</h2>
				<div className="mb-4">{children}</div>
				{showAction && (
					<div className="flex flex-col md:flex-row justify-end space-y-2 md:space-y-0 md:space-x-4">
						<button
							onClick={onClose}
							className="px-4 py-2 bg-gray-300 text-center text-gray-700 rounded-md hover:bg-gray-400"
						>
							{firstText}
						</button>
						<button
							onClick={onConfirm}
							className="px-4 py-2 bg-red-500 text-center text-white rounded-md hover:bg-red-600"
						>
							{secondText}
						</button>
					</div>
				)}
			</div>
		</div>
	)
}

export default Modal
