import React, { useEffect, useState } from 'react'
import { db } from '../firebase' // Pastikan path sesuai
import { FaEye, FaTrash } from 'react-icons/fa'
import Modal from '../components/Modal'
import { Helmet } from 'react-helmet'

const Users = () => {
	const [users, setUsers] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState('')
	const [searchTerm, setSearchTerm] = useState('')
	const [selectedUser, setSelectedUser] = useState(null) // Untuk modal
	const [showModal, setShowModal] = useState(false) // Untuk modal
	const [showConfirmModal, setShowConfirmModal] = useState(false) // Untuk konfirmasi hapus
	const [userToDelete, setUserToDelete] = useState(null) // Untuk konfirmasi hapus

	const [userCount, setUserCount] = useState(0)

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const usersCollection = db.collection('users')
				const snapshot = await usersCollection.get()
				if (snapshot.empty) {
					setError('No users found')
					return
				}

        setUserCount(snapshot.size)
				const usersList = await Promise.all(
					snapshot.docs.map(async (doc) => {
						const userDoc = doc.data()
						const displayName = userDoc.displayName || 'No Name'
						return {
							id: doc.id,
							name: displayName,
							email: userDoc.email || 'No Email',
							photoURL: userDoc.photoURL || '',
							inventory: userDoc.inventory || {},
							category: userDoc.category || '',
							loginHistory: [], // Placeholder
						}
					})
				)

				// Urutkan data berdasarkan email
				usersList.sort((a, b) => a.email.localeCompare(b.email))

				setUsers(usersList)
			} catch (err) {
				setError('Failed to fetch users')
				console.error('Error fetching users:', err)
			} finally {
				setLoading(false)
			}
		}

		fetchUsers()
	}, [])

	const fetchLoginHistory = async (userId) => {
		try {
			const loginHistoryCollection = db
				.collection('users')
				.doc(userId)
				.collection('loginHistory')
			const snapshot = await loginHistoryCollection.get()
			if (snapshot.empty) return []

			return snapshot.docs.map((doc) => doc.data())
		} catch (err) {
			console.error('Error fetching login history:', err)
			return []
		}
	}

	const handleViewDetails = async (user) => {
		const loginHistory = await fetchLoginHistory(user.id)
		setSelectedUser({
			...user,
			loginHistory,
		})
		setShowModal(true)
	}

	const handleDelete = (user) => {
		setUserToDelete(user)
		setShowConfirmModal(true)
	}

	const confirmDelete = async () => {
		if (userToDelete) {
			try {
				await db.collection('users').doc(userToDelete.id).delete()
				setUsers(users.filter((user) => user.id !== userToDelete.id))
			} catch (err) {
				console.error('Error deleting user:', err)
			}
			setShowConfirmModal(false)
			setUserToDelete(null)
		}
	}

	if (loading) {
		return (
			<div className="flex items-center justify-center h-screen">
				<p className="text-lg text-gray-500">Loading...</p>
			</div>
		)
	}

	if (error) return <p className="text-red-500">{error}</p>

	return (
		<div className="p-8 mt-24">
			<h1 className="text-3xl font-bold mb-6 text-center w-full">
				Database Pengguna Aibeecara
			</h1>
			<div className="mb-4">
				<input
					type="text"
					placeholder="Cari pengguna..."
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					className="w-full px-3 py-2 border border-primary rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
				/>
			</div>

      <div className='bg-secondary text-white rounded-lg px-4 py-2 mb-4 font-bold text-center text-xl'>
        <p>Jumlah: {userCount} Pengguna</p>
      </div>

			<div className="overflow-x-auto">
				<table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
					<thead className="bg-primary text-white rounded-t-lg">
						<tr>
							<th className="py-2 px-4 border-b border-r border-gray-300 w-fit">
								No.
							</th>
							{/* <th className="py-2 px-4 border-b border-r border-gray-300">
								ID
							</th> */}
							<th className="py-2 px-4 border-b border-r border-gray-300">
								Nama
							</th>
							<th className="py-2 px-4 border-b border-r border-gray-300">
								Email
							</th>
							<th className="py-2 px-4 border-b border-r border-gray-300 text-center">
								Aksi
							</th>{' '}
							{/* Kolom Aksi */}
						</tr>
					</thead>
					<tbody>
						{users
							.filter(
								(user) =>
									user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
									user.email.toLowerCase().includes(searchTerm.toLowerCase())
							)
							.map((user, index) => (
								<tr key={user.id} className="border-b border-gray-300">
									<td className="py-2 px-4 border-b border-r border-gray-300 w-fit">
										{index + 1}
									</td>
									{/* <td className="py-2 px-4 border-b border-r border-gray-300">
										{user.id}
									</td> */}
									<td className="py-2 px-4 border-b border-r border-gray-300">
										{user.name}
									</td>
									<td className="py-2 px-4 border-b border-r border-gray-300">
										{user.email}
									</td>
									<td className="py-2 px-4 border-b text-center border-gray-300">
										<button
											onClick={() => handleViewDetails(user)}
											className="text-blue-500 hover:text-blue-700 mx-2"
										>
											<FaEye className="inline" />
										</button>
										<button
											onClick={() => handleDelete(user)}
											className="text-red-500 hover:text-red-700 mx-2"
										>
											<FaTrash className="inline" />
										</button>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>

			{/* Modal untuk Detail Pengguna */}
			<Modal
				isOpen={showModal}
				onClose={() => setShowModal(false)}
				title="Detail Pengguna"
				showAction={false}
			>
				{selectedUser && (
					<div className="flex items-start">
						{/* Data Pengguna */}
						<div className="flex-1 pr-4">
							<p>
								<strong>Nama Pengguna:</strong>{' '}
								{selectedUser.name || 'Tidak Ada Nama'}
							</p>
							<p>
								<strong>Email:</strong>{' '}
								{selectedUser.email || 'Tidak Ada Email'}
							</p>
							<p>
								<strong>Kategori:</strong>{' '}
								{selectedUser.category || 'Tidak Ada Kategori'}
							</p>
							<p>
								<strong>Hati:</strong> {selectedUser.inventory?.heart || 0}
							</p>
							<p>
								<strong>Honey:</strong> {selectedUser.inventory?.honey || 0}
							</p>
							<p>
								<strong>Jar:</strong> {selectedUser.inventory?.jar || 0}
							</p>
							<p>
								<strong>Kosakata:</strong> {selectedUser.inventory?.vocabs || 0}
							</p>

							{/* Riwayat Login */}
							<div className="mt-4">
								<h3 className="font-semibold mb-2">Riwayat Login:</h3>
								{selectedUser.loginHistory.length > 0 ? (
									selectedUser.loginHistory.map((history, index) => (
										<div
											key={index}
											className="bg-gray-100 p-4 mb-4 rounded-md shadow-md"
										>
											<p>
												<strong>Perangkat:</strong> {history.device}
											</p>
											<p>
												<strong>Waktu:</strong>{' '}
												{new Date(
													history.timestamp.seconds * 1000
												).toLocaleString('id-ID')}
											</p>
										</div>
									))
								) : (
									<p>Riwayat login tidak tersedia.</p>
								)}
							</div>
						</div>

						{/* Foto Pengguna */}
						<div className="flex-shrink-0">
							<img
								src={selectedUser.photoURL}
								alt="Foto Pengguna"
								className="w-32 h-32 object-cover rounded-full border-2 border-gray-300"
							/>
						</div>
					</div>
				)}
			</Modal>

			{/* Modal untuk Konfirmasi Hapus */}
			<Modal
				isOpen={showConfirmModal}
				onClose={() => setShowConfirmModal(false)}
				onConfirm={confirmDelete}
				title="Konfirmasi Penghapusan"
        showAction={true}
        firstText="Batal"
        secondText="Hapus"
			>
				<p>Apakah Anda yakin ingin menghapus pengguna ini?</p>
			</Modal>

      <Helmet>
        <title>Database Pengguna - aibeecara</title>
      </Helmet>
		</div>
	)
}

export default Users
